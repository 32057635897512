import { createRouter, createWebHistory } from 'vue-router'
import { constantRoutes, asyncRoutes } from './routes'
import { filterAsyncRoutes } from './permission'

const router = createRouter({
    history: createWebHistory(),
    // base: '/webcontrol/',
    routes:constantRoutes
});

router.beforeEach((to, from, next) => {    
    document.title = '天枢系统';
    const tokenStr =JSON.parse(localStorage.getItem('userinfo'));
    if (to.path === '/') {
        return next();
    }
    if (!tokenStr){
        next('/')
    }else{
        if (!router.hasDynamicRoutes) {
            // 如果没有加载过动态路由，根据角色过滤路由并添加
            const accessRoutes = filterAsyncRoutes(asyncRoutes, tokenStr.role);
            const Routeraddlist={
                path: '/index',
                component: () => import('../components/menu/index.vue'),
                redirect: accessRoutes[0].path,
                children:accessRoutes
            };
            router.addRoute(Routeraddlist); // 动态添加路由
            router.hasDynamicRoutes = true; // 标记已添加
            next({ ...to, replace: true }); // 确保路由更新
        } else {
            next(); // 已加载动态路由，直接跳转
        }    
    }
});
// 方法：重置动态路由标记
export function resetDynamicRoutes() {
    router.hasDynamicRoutes = false
}
export default router;