<template>
    <router-view/>
</template>
<script>
export default {
  data(){
      return{}
  },
  created() {    
  },
  mounted(){ 
  }
}
</script>
<style>
/* *{
  font-size: 16px !important;
} */
</style>